@import"https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback";*,:before,:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
}

:before,:after {
    --tw-content: ""
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
    font-feature-settings: normal
}

body {
    margin: 0;
    line-height: inherit
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1,h2,h3,h4,h5,h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

b,strong {
    font-weight: bolder
}

code,kbd,samp,pre {
    font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,input,optgroup,select,textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,select {
    text-transform: none
}

button,[type=button],[type=reset],[type=submit] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,dl,dd,h1,h2,h3,h4,h5,h6,hr,figure,p,pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,ul,menu {
    list-style: none;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder,textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder,textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button,[role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,svg,video,canvas,audio,iframe,embed,object {
    display: block;
    vertical-align: middle
}

img,video {
    max-width: 100%;
    height: auto
}



::-webkit-date-and-time-value {
    min-height: 1.5em
}

::-webkit-datetime-edit,::-webkit-datetime-edit-year-field,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute-field,::-webkit-datetime-edit-second-field,::-webkit-datetime-edit-millisecond-field,::-webkit-datetime-edit-meridiem-field {
    padding-top: 0;
    padding-bottom: 0
}

select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right .5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact
}

[multiple] {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    padding-right: .75rem;
    -webkit-print-color-adjust: unset;
    print-color-adjust: unset
}

[type=checkbox],[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 #0000
}

[type=checkbox] {
    border-radius: 0
}

[type=radio] {
    border-radius: 100%
}

[type=checkbox]:focus,[type=radio]:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow)
}

[type=checkbox]:checked,[type=radio]:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

[type=checkbox]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")
}

[type=radio]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e")
}

[type=checkbox]:checked:hover,[type=checkbox]:checked:focus,[type=radio]:checked:hover,[type=radio]:checked:focus {
    border-color: transparent;
    background-color: currentColor
}

[type=checkbox]:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

[type=checkbox]:indeterminate:hover,[type=checkbox]:indeterminate:focus {
    border-color: transparent;
    background-color: currentColor
}

[type=file] {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    line-height: inherit
}

[type=file]:focus {
    outline: 1px solid ButtonText;
    outline: 1px auto -webkit-focus-ring-color
}

*,:before,:after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

.form-input,.form-textarea,.form-select,.form-multiselect {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000
}

.form-input:focus,.form-textarea:focus,.form-select:focus,.form-multiselect:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow);
    border-color: #2563eb
}

.form-input::-moz-placeholder,.form-textarea::-moz-placeholder {
    color: #6b7280;
    opacity: 1
}

.form-input::placeholder,.form-textarea::placeholder {
    color: #6b7280;
    opacity: 1
}

.form-input::-webkit-datetime-edit-fields-wrapper {
    padding: 0
}

.form-input::-webkit-date-and-time-value {
    min-height: 1.5em
}

.form-input::-webkit-datetime-edit,.form-input::-webkit-datetime-edit-year-field,.form-input::-webkit-datetime-edit-month-field,.form-input::-webkit-datetime-edit-day-field,.form-input::-webkit-datetime-edit-hour-field,.form-input::-webkit-datetime-edit-minute-field,.form-input::-webkit-datetime-edit-second-field,.form-input::-webkit-datetime-edit-millisecond-field,.form-input::-webkit-datetime-edit-meridiem-field {
    padding-top: 0;
    padding-bottom: 0
}

.form-checkbox,.form-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 #0000
}

.form-checkbox {
    border-radius: 0
}

.form-checkbox:focus,.form-radio:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow)
}

.form-checkbox:checked,.form-radio:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

.form-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")
}

.form-checkbox:checked:hover,.form-checkbox:checked:focus,.form-radio:checked:hover,.form-radio:checked:focus {
    border-color: transparent;
    background-color: currentColor
}

.form-checkbox:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

.form-checkbox:indeterminate:hover,.form-checkbox:indeterminate:focus {
    border-color: transparent;
    background-color: currentColor
}

.h1 {
    font-size: 2.25rem;
    line-height: 1.25;
    font-weight: 800;
    letter-spacing: -.05em
}

.h2 {
    font-size: 1.88rem;
    line-height: 1.33;
    font-weight: 800;
    letter-spacing: -.05em
}

.h3 {
    font-size: 1.88rem;
    line-height: 1.33;
    letter-spacing: -.01em;
    font-weight: 800
}

.h4 {
    font-size: 1.5rem;
    line-height: 1.33;
    font-weight: 800;
    letter-spacing: -.025em
}

@media (min-width: 768px) {
    .h1 {
        font-size:3rem;
        line-height: 1.25;
        letter-spacing: -.02em
    }

    .h2 {
        font-size: 2.25rem;
        line-height: 1.25;
        letter-spacing: -.02em
    }
}

.btn,.btn-lg,.btn-sm,.btn-xs {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;
    border-width: 1px;
    border-color: transparent;
    font-size: .875rem;
    line-height: 1.5715;
    font-weight: 500;
    line-height: 1.25rem;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
    transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
    transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4,0,.2,1)
}

.btn {
    padding: .5rem .75rem
}

.btn-lg {
    padding: .75rem 1rem
}

.btn-sm {
    padding: .25rem .5rem
}

.btn-xs {
    padding: .125rem .5rem
}

.form-input,.form-textarea,.form-multiselect,.form-select,.form-checkbox,.form-radio {
    border-width: 1px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    font-size: .875rem;
    line-height: 1.5715;
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity))
}

.form-input,.form-textarea,.form-multiselect,.form-select,.form-checkbox {
    border-radius: .25rem
}

.form-input,.form-textarea,.form-multiselect,.form-select {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity));
    padding: .5rem .75rem;
    line-height: 1.25rem;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.form-input:hover,.form-textarea:hover,.form-multiselect:hover,.form-select:hover {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity))
}

.form-input:focus,.form-textarea:focus,.form-multiselect:focus,.form-select:focus {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity))
}

.form-input::-moz-placeholder,.form-textarea::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(148 163 184 / var(--tw-placeholder-opacity))
}

.form-input::placeholder,.form-textarea::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(148 163 184 / var(--tw-placeholder-opacity))
}

.form-select {
    padding-right: 2.5rem
}

.form-checkbox,.form-radio {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity))
}

.no-scrollbar::-webkit-scrollbar {
    display: none
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none
}

:root {
    --range-thumb-size: 36px
}

input[type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #ccc;
    border-radius: 3px;
    height: 6px;
    margin-top: (--range-thumb-size - 6px) * .5;
    margin-bottom: (--range-thumb-size - 6px) * .5;
    --thumb-size: # {
        --range-thumb-size
    }
}

input[type=range]::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size
}

input[type=range]::-moz-range-thumb {
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size
}

input[type=range]::-ms-thumb {
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size
}

input[type=range]::-moz-focus-outer {
    border: 0
}

.form-switch {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 44px
}

.form-switch label {
    display: block;
    height: 1.5rem;
    cursor: pointer;
    overflow: hidden;
    border-radius: 9999px
}

.form-switch label>span:first-child {
    position: absolute;
    display: block;
    border-radius: 9999px;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    right: 50%;
    transition: all .15s ease-out
}

.form-switch input[type=checkbox]:checked+label {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity))
}

.form-switch input[type=checkbox]:checked+label>span:first-child {
    left: 22px
}

.form-switch input[type=checkbox]:disabled+label {
    cursor: not-allowed;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.form-switch input[type=checkbox]:disabled+label>span:first-child {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity))
}

.flatpickr-calendar {
    background: transparent;
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 0;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 307.875px;
    box-sizing: border-box;
    touch-action: manipulation;
    background: #fff;
    box-shadow: 1px 0 #e6e6e6,-1px 0 #e6e6e6,0 1px #e6e6e6,0 -1px #e6e6e6,0 3px 13px #00000014
}

.flatpickr-calendar.open,.flatpickr-calendar.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible
}

.flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999
}

.flatpickr-calendar.animate.open {
    animation: fpFadeInDown .3s cubic-bezier(.23,1,.32,1)
}

.flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px)
}

.flatpickr-calendar.static.open {
    z-index: 999;
    display: block
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
    box-shadow: none!important
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
    box-shadow: -2px 0 #e6e6e6,5px 0 #e6e6e6
}

.flatpickr-calendar .hasWeeks .dayContainer,.flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0
}

.flatpickr-calendar.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid #e6e6e6
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto
}

.flatpickr-calendar:before,.flatpickr-calendar:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    left: 22px
}

.flatpickr-calendar.rightMost:before,.flatpickr-calendar.arrowRight:before,.flatpickr-calendar.rightMost:after,.flatpickr-calendar.arrowRight:after {
    left: auto;
    right: 22px
}

.flatpickr-calendar.arrowCenter:before,.flatpickr-calendar.arrowCenter:after {
    left: 50%;
    right: 50%
}

.flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px
}

.flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px
}

.flatpickr-calendar.arrowTop:before,.flatpickr-calendar.arrowTop:after {
    bottom: 100%
}

.flatpickr-calendar.arrowTop:before {
    border-bottom-color: #e6e6e6
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #fff
}

.flatpickr-calendar.arrowBottom:before,.flatpickr-calendar.arrowBottom:after {
    top: 100%
}

.flatpickr-calendar.arrowBottom:before {
    border-top-color: #e6e6e6
}

.flatpickr-calendar.arrowBottom:after {
    border-top-color: #fff
}

.flatpickr-calendar:focus {
    outline: 0
}

.flatpickr-wrapper {
    position: relative;
    display: inline-block
}

.flatpickr-months {
    display: flex
}

.flatpickr-months .flatpickr-month {
    background: transparent;
    color: #000000e6;
    fill: #000000e6;
    height: 34px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    overflow: hidden;
    flex: 1
}

.flatpickr-months .flatpickr-prev-month,.flatpickr-months .flatpickr-next-month {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 34px;
    padding: 10px;
    z-index: 3;
    color: #000000e6;
    fill: #000000e6
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
    display: none
}

.flatpickr-months .flatpickr-prev-month i,.flatpickr-months .flatpickr-next-month i {
    position: relative
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
    left: 0
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
    right: 0
}

.flatpickr-months .flatpickr-prev-month:hover,.flatpickr-months .flatpickr-next-month:hover {
    color: #959ea9
}

.flatpickr-months .flatpickr-prev-month:hover svg,.flatpickr-months .flatpickr-next-month:hover svg {
    fill: #f64747
}

.flatpickr-months .flatpickr-prev-month svg,.flatpickr-months .flatpickr-next-month svg {
    width: 14px;
    height: 14px
}

.flatpickr-months .flatpickr-prev-month svg path,.flatpickr-months .flatpickr-next-month svg path {
    transition: fill .1s;
    fill: inherit
}

.numInputWrapper {
    position: relative;
    height: auto
}

.numInputWrapper input,.numInputWrapper span {
    display: inline-block
}

.numInputWrapper input {
    width: 100%
}

.numInputWrapper input::-ms-clear {
    display: none
}

.numInputWrapper input::-webkit-outer-spin-button,.numInputWrapper input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none
}

.numInputWrapper span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(57,57,57,.15);
    box-sizing: border-box
}

.numInputWrapper span:hover {
    background: rgba(0,0,0,.1)
}

.numInputWrapper span:active {
    background: rgba(0,0,0,.2)
}

.numInputWrapper span:after {
    display: block;
    content: "";
    position: absolute
}

.numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0
}

.numInputWrapper span.arrowUp:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(57,57,57,.6);
    top: 26%
}

.numInputWrapper span.arrowDown {
    top: 50%
}

.numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(57,57,57,.6);
    top: 40%
}

.numInputWrapper span svg {
    width: inherit;
    height: auto
}

.numInputWrapper span svg path {
    fill: #00000080
}

.numInputWrapper:hover {
    background: rgba(0,0,0,.05)
}

.numInputWrapper:hover span {
    opacity: 1
}

.flatpickr-current-month {
    font-size: 135%;
    line-height: inherit;
    font-weight: 300;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 7.48px 0 0;
    line-height: 1;
    height: 34px;
    display: inline-block;
    text-align: center;
    transform: translateZ(0)
}

.flatpickr-current-month span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: .5ch;
    padding: 0
}

.flatpickr-current-month span.cur-month:hover {
    background: rgba(0,0,0,.05)
}

.flatpickr-current-month .numInputWrapper {
    width: 6ch;
    width: 7ch\fffd;
    display: inline-block
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: #000000e6
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: #000000e6
}

.flatpickr-current-month input.cur-year {
    background: transparent;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 .5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield
}

.flatpickr-current-month input.cur-year:focus {
    outline: 0
}

.flatpickr-current-month input.cur-year[disabled],.flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: #00000080;
    background: transparent;
    pointer-events: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    appearance: menulist;
    background: transparent;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: -1px 0 0;
    outline: none;
    padding: 0 0 0 .5ch;
    position: relative;
    vertical-align: initial;
    -webkit-box-sizing: border-box;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    width: auto
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,.flatpickr-current-month .flatpickr-monthDropdown-months:active {
    outline: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: rgba(0,0,0,.05)
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: transparent;
    outline: none;
    padding: 0
}

.flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    height: 28px
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: flex;
    flex: 1
}

span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    background: transparent;
    color: #0000008a;
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    flex: 1;
    font-weight: bolder
}

.dayContainer,.flatpickr-weeks {
    padding: 1px 0 0
}

.flatpickr-days {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    width: 307.875px
}

.flatpickr-days:focus {
    outline: 0
}

.dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: 307.875px;
    min-width: 307.875px;
    max-width: 307.875px;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-around;
    transform: translateZ(0);
    opacity: 1
}

.dayContainer+.dayContainer {
    box-shadow: -1px 0 #e6e6e6
}

.flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    box-sizing: border-box;
    color: #393939;
    cursor: pointer;
    font-weight: 400;
    width: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: 39px;
    height: 39px;
    line-height: 39px;
    margin: 0;
    display: inline-block;
    position: relative;
    justify-content: center;
    text-align: center
}

.flatpickr-day.inRange,.flatpickr-day.prevMonthDay.inRange,.flatpickr-day.nextMonthDay.inRange,.flatpickr-day.today.inRange,.flatpickr-day.prevMonthDay.today.inRange,.flatpickr-day.nextMonthDay.today.inRange,.flatpickr-day:hover,.flatpickr-day.prevMonthDay:hover,.flatpickr-day.nextMonthDay:hover,.flatpickr-day:focus,.flatpickr-day.prevMonthDay:focus,.flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    background: #e6e6e6;
    border-color: #e6e6e6
}

.flatpickr-day.today {
    border-color: #959ea9
}

.flatpickr-day.today:hover,.flatpickr-day.today:focus {
    border-color: #959ea9;
    background: #959ea9;
    color: #fff
}

.flatpickr-day.selected,.flatpickr-day.startRange,.flatpickr-day.endRange,.flatpickr-day.selected.inRange,.flatpickr-day.startRange.inRange,.flatpickr-day.endRange.inRange,.flatpickr-day.selected:focus,.flatpickr-day.startRange:focus,.flatpickr-day.endRange:focus,.flatpickr-day.selected:hover,.flatpickr-day.startRange:hover,.flatpickr-day.endRange:hover,.flatpickr-day.selected.prevMonthDay,.flatpickr-day.startRange.prevMonthDay,.flatpickr-day.endRange.prevMonthDay,.flatpickr-day.selected.nextMonthDay,.flatpickr-day.startRange.nextMonthDay,.flatpickr-day.endRange.nextMonthDay {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7
}

.flatpickr-day.selected.startRange,.flatpickr-day.startRange.startRange,.flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px
}

.flatpickr-day.selected.endRange,.flatpickr-day.startRange.endRange,.flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0
}

.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)),.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
    box-shadow: -10px 0 #569ff7
}

.flatpickr-day.selected.startRange.endRange,.flatpickr-day.startRange.startRange.endRange,.flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px
}

.flatpickr-day.inRange {
    border-radius: 0;
    box-shadow: -5px 0 #e6e6e6,5px 0 #e6e6e6
}

.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover,.flatpickr-day.prevMonthDay,.flatpickr-day.nextMonthDay,.flatpickr-day.notAllowed,.flatpickr-day.notAllowed.prevMonthDay,.flatpickr-day.notAllowed.nextMonthDay {
    color: #3939394d;
    background: transparent;
    border-color: transparent;
    cursor: default
}

.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover {
    cursor: not-allowed;
    color: #3939391a
}

.flatpickr-day.week.selected {
    border-radius: 0;
    box-shadow: -5px 0 #569ff7,5px 0 #569ff7
}

.flatpickr-day.hidden {
    visibility: hidden
}

.rangeMode .flatpickr-day {
    margin-top: 1px
}

.flatpickr-weekwrapper {
    float: left
}

.flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    box-shadow: 1px 0 #e6e6e6
}

.flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px
}

.flatpickr-weekwrapper span.flatpickr-day,.flatpickr-weekwrapper span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: #3939394d;
    background: transparent;
    cursor: default;
    border: none
}

.flatpickr-innerContainer {
    display: block;
    display: flex;
    box-sizing: border-box;
    overflow: hidden
}

.flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    box-sizing: border-box
}

.flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex
}

.flatpickr-time:after {
    content: "";
    display: table;
    clear: both
}

.flatpickr-time .numInputWrapper {
    flex: 1;
    width: 40%;
    height: 40px;
    float: left
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #393939
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #393939
}

.flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%
}

.flatpickr-time.time24hr .numInputWrapper {
    width: 49%
}

.flatpickr-time input {
    background: transparent;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: #393939;
    font-size: 14px;
    position: relative;
    box-sizing: border-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield
}

.flatpickr-time input.flatpickr-hour {
    font-weight: 700
}

.flatpickr-time input.flatpickr-minute,.flatpickr-time input.flatpickr-second {
    font-weight: 400
}

.flatpickr-time input:focus {
    outline: 0;
    border: 0
}

.flatpickr-time .flatpickr-time-separator,.flatpickr-time .flatpickr-am-pm {
    height: inherit;
    float: left;
    line-height: inherit;
    color: #393939;
    font-weight: 700;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    align-self: center
}

.flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400
}

.flatpickr-time input:hover,.flatpickr-time .flatpickr-am-pm:hover,.flatpickr-time input:focus,.flatpickr-time .flatpickr-am-pm:focus {
    background: #eee
}

.flatpickr-input[readonly] {
    cursor: pointer
}

@keyframes fpFadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0,-20px,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

* {
    --calendarPadding: 24px;
    --daySize: 36px;
    --daysWidth: calc(var(--daySize)*7)
}

@keyframes fpFadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0,-8px,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.flatpickr-calendar {
    border: inherit;
    left: 50%;
    border-radius: .25rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity));
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .08), 0 4px 6px -2px rgba(0, 0, 0, .01);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    margin-left: calc(calc(var(--daysWidth) + calc(var(--calendarPadding)*2))*.5*-1);
    padding: var(--calendarPadding);
    width: calc(var(--daysWidth) + calc(var(--calendarPadding)*2))
}

@media (min-width: 1024px) {
    .flatpickr-calendar {
        left:0px;
        right: auto;
        margin-left: 0
    }
}

.flatpickr-right.flatpickr-calendar {
    right: 0px;
    left: auto;
    margin-left: 0
}

.flatpickr-calendar.animate.open {
    animation: fpFadeInDown .2s ease-out
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 4px)
}

.flatpickr-calendar.static.open {
    z-index: 20
}

.flatpickr-days {
    width: var(--daysWidth)
}

.dayContainer {
    width: var(--daysWidth);
    min-width: var(--daysWidth);
    max-width: var(--daysWidth)
}

.flatpickr-day {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity));
    font-size: .875rem;
    line-height: 1.5715;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity));
    max-width: var(--daySize);
    height: var(--daySize);
    line-height: var(--daySize)
}

.flatpickr-day,.flatpickr-day.prevMonthDay,.flatpickr-day.nextMonthDay {
    border: none
}

.flatpickr-day,.flatpickr-day.prevMonthDay,.flatpickr-day.nextMonthDay,.flatpickr-day.selected.startRange,.flatpickr-day.startRange.startRange,.flatpickr-day.endRange.startRange,.flatpickr-day.selected.endRange,.flatpickr-day.startRange.endRange,.flatpickr-day.endRange.endRange,.flatpickr-day.selected.startRange.endRange,.flatpickr-day.startRange.startRange.endRange,.flatpickr-day.endRange.startRange.endRange {
    border-radius: 0
}

.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover,.flatpickr-day.prevMonthDay,.flatpickr-day.nextMonthDay,.flatpickr-day.notAllowed,.flatpickr-day.notAllowed.prevMonthDay,.flatpickr-day.notAllowed.nextMonthDay {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.rangeMode .flatpickr-day {
    margin: 0
}

.flatpickr-day.selected,.flatpickr-day.startRange,.flatpickr-day.endRange,.flatpickr-day.selected.inRange,.flatpickr-day.startRange.inRange,.flatpickr-day.endRange.inRange,.flatpickr-day.selected:focus,.flatpickr-day.startRange:focus,.flatpickr-day.endRange:focus,.flatpickr-day.selected:hover,.flatpickr-day.startRange:hover,.flatpickr-day.endRange:hover,.flatpickr-day.selected.prevMonthDay,.flatpickr-day.startRange.prevMonthDay,.flatpickr-day.endRange.prevMonthDay,.flatpickr-day.selected.nextMonthDay,.flatpickr-day.startRange.nextMonthDay,.flatpickr-day.endRange.nextMonthDay {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity))
}

.flatpickr-day.inRange,.flatpickr-day.prevMonthDay.inRange,.flatpickr-day.nextMonthDay.inRange,.flatpickr-day.today.inRange,.flatpickr-day.prevMonthDay.today.inRange,.flatpickr-day.nextMonthDay.today.inRange,.flatpickr-day:hover,.flatpickr-day.prevMonthDay:hover,.flatpickr-day.nextMonthDay:hover,.flatpickr-day:focus,.flatpickr-day.prevMonthDay:focus,.flatpickr-day.nextMonthDay:focus,.flatpickr-day.today:hover,.flatpickr-day.today:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity))
}

.flatpickr-day.inRange,.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)),.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
    box-shadow: none
}

.flatpickr-months {
    align-items: center;
    margin-top: -8px;
    margin-bottom: 6px
}

.flatpickr-months .flatpickr-prev-month,.flatpickr-months .flatpickr-next-month {
    position: static;
    height: auto;
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))
}

.flatpickr-months .flatpickr-prev-month svg,.flatpickr-months .flatpickr-next-month svg {
    width: 7px;
    height: 11px
}

.flatpickr-months .flatpickr-prev-month:hover,.flatpickr-months .flatpickr-next-month:hover,.flatpickr-months .flatpickr-prev-month:hover svg,.flatpickr-months .flatpickr-next-month:hover svg {
    fill: inherit;
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.flatpickr-months .flatpickr-prev-month {
    margin-left: -10px
}

.flatpickr-months .flatpickr-next-month {
    margin-right: -10px
}

.flatpickr-months .flatpickr-month {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity));
    height: auto;
    line-height: inherit
}

.flatpickr-current-month {
    font-size: .875rem;
    line-height: 1.5715;
    font-weight: 500;
    position: static;
    height: auto;
    width: auto;
    left: auto;
    padding: 0
}

.flatpickr-current-month span.cur-month {
    margin: 0;
    font-weight: 500
}

.flatpickr-current-month span.cur-month:hover {
    background: none
}

.flatpickr-current-month input.cur-year {
    font-weight: inherit;
    box-shadow: none!important
}

.numInputWrapper:hover {
    background: none
}

.numInputWrapper span {
    display: none
}

span.flatpickr-weekday {
    font-size: .75rem;
    line-height: 1.5;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.flatpickr-calendar.arrowTop:before,.flatpickr-calendar.arrowTop:after,.flatpickr-calendar.arrowBottom:before,.flatpickr-calendar.arrowBottom:after {
    display: none
}

.form-input:focus,.form-textarea:focus,.form-multiselect:focus,.form-select:focus,.form-checkbox:focus,.form-radio:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border-width: 0
}

.pointer-events-none {
    pointer-events: none
}

.invisible {
    visibility: hidden
}

.collapse {
    visibility: collapse
}

.static {
    position: static
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.sticky {
    position: sticky
}

.inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.bottom-0 {
    bottom: 0px
}

.bottom-full {
    bottom: 100%
}

.left-0 {
    left: 0px
}

.left-1\/2 {
    left: 50%
}

.right-0 {
    right: 0px
}

.right-auto {
    right: auto
}

.top-0 {
    top: 0px
}

.top-20 {
    top: 5rem
}

.top-full {
    top: 100%
}

.z-10 {
    z-index: 10
}

.z-30 {
    z-index: 30
}

.z-40 {
    z-index: 40
}

.z-50 {
    z-index: 50
}

.z-60 {
    z-index: 60
}

.col-span-full {
    grid-column: 1 / -1
}

.-m-1 {
    margin: -.25rem
}

.mx-3 {
    margin-left: .75rem;
    margin-right: .75rem
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem
}

.my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem
}

.-mb-px {
    margin-bottom: -1px
}

.-ml-px {
    margin-left: -1px
}

.-mr-48 {
    margin-right: -12rem
}

.-mt-4 {
    margin-top: -1rem
}

.mb-0 {
    margin-bottom: 0
}

.mb-0\.5 {
    margin-bottom: .125rem
}

.mb-1 {
    margin-bottom: .25rem
}

.mb-10 {
    margin-bottom: 2.5rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mb-3 {
    margin-bottom: .75rem
}

.mb-4 {
    margin-bottom: 1rem
}

.mb-8 {
    margin-bottom: 2rem
}

.ml-1 {
    margin-left: .25rem
}

.ml-2 {
    margin-left: .5rem
}

.ml-3 {
    margin-left: .75rem
}

.ml-4 {
    margin-left: 1rem
}

.mr-16 {
    margin-right: 4rem
}

.mr-2 {
    margin-right: .5rem
}

.mr-3 {
    margin-right: .75rem
}

.mt-1 {
    margin-top: .25rem
}

.mt-3 {
    margin-top: .75rem
}

.mt-auto {
    margin-top: auto
}

.block {
    display: block
}

.flex {
    display: flex
}

.inline-flex {
    display: inline-flex
}

.table {
    display: table
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.h-10 {
    height: 2.5rem
}

.h-16 {
    height: 4rem
}

.h-2 {
    height: .5rem
}

.h-2\.5 {
    height: .625rem
}

.h-3 {
    height: .75rem
}

.h-4 {
    height: 1rem
}

.h-5 {
    height: 1.25rem
}

.h-6 {
    height: 1.5rem
}

.h-8 {
    height: 2rem
}

.h-9 {
    height: 2.25rem
}

.h-screen {
    height: 100vh
}

.max-h-full {
    max-height: 100%
}

.w-10 {
    width: 2.5rem
}

.w-2 {
    width: .5rem
}

.w-2\.5 {
    width: .625rem
}

.w-3 {
    width: .75rem
}

.w-4 {
    width: 1rem
}

.w-6 {
    width: 1.5rem
}

.w-60 {
    width: 15rem
}

.w-64 {
    width: 16rem
}

.w-8 {
    width: 2rem
}

.w-9 {
    width: 2.25rem
}

.w-full {
    width: 100%
}

.w-px {
    width: 1px
}

.min-w-36 {
    min-width: 9rem
}

.min-w-44 {
    min-width: 11rem
}

.min-w-56 {
    min-width: 14rem
}

.min-w-80 {
    min-width: 20rem
}

.max-w-2xl {
    max-width: 42rem
}

.max-w-9xl {
    max-width: 96rem
}

.flex-1 {
    flex: 1 1 0%
}

.flex-shrink-0,.shrink-0 {
    flex-shrink: 0
}

.grow {
    flex-grow: 1
}

.table-auto {
    table-layout: auto
}

.origin-top-right {
    transform-origin: top right
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-x-64 {
    --tw-translate-x: -16rem;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-2 {
    --tw-translate-y: -.5rem;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-4 {
    --tw-translate-y: 1rem;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
    cursor: pointer
}

.appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.grid-flow-col {
    grid-auto-flow: column
}

.grid-cols-12 {
    grid-template-columns: repeat(12,minmax(0,1fr))
}

.flex-col {
    flex-direction: column
}

.flex-wrap {
    flex-wrap: wrap
}

.items-start {
    align-items: flex-start
}

.items-end {
    align-items: flex-end
}

.items-center {
    align-items: center
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.gap-2 {
    gap: .5rem
}

.gap-6 {
    gap: 1.5rem
}

.-space-x-3>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-8>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.divide-y>:not([hidden])~:not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.divide-slate-100>:not([hidden])~:not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-divide-opacity))
}

.self-start {
    align-self: flex-start
}

.self-end {
    align-self: flex-end
}

.self-center {
    align-self: center
}

.overflow-auto {
    overflow: auto
}

.overflow-hidden {
    overflow: hidden
}

.overflow-x-auto {
    overflow-x: auto
}

.overflow-y-auto {
    overflow-y: auto
}

.overflow-x-hidden {
    overflow-x: hidden
}

.overflow-y-scroll {
    overflow-y: scroll
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.whitespace-nowrap {
    white-space: nowrap
}

.rounded {
    border-radius: .25rem
}

.rounded-full {
    border-radius: 9999px
}

.rounded-sm {
    border-radius: .125rem
}

.border {
    border-width: 1px
}

.border-0 {
    border-width: 0px
}

.border-2 {
    border-width: 2px
}

.border-b {
    border-bottom-width: 1px
}

.border-l {
    border-left-width: 1px
}

.border-t {
    border-top-width: 1px
}

.border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity))
}

.border-slate-100 {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity))
}

.border-slate-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity))
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity))
}

.bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity))
}

.bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity))
}

.bg-rose-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity))
}

.bg-sky-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity))
}

.bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}

.bg-slate-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))
}

.bg-slate-800 {
    /* --tw-bg-opacity: 1; */
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity))
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity))
}

.bg-opacity-30 {
    --tw-bg-opacity: .3
}

.fill-current {
    fill: currentColor
}

.p-2 {
    padding: .5rem
}

.p-3 {
    padding: .75rem
}

.p-4 {
    padding: 1rem
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem
}

.px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem
}

.px-3 {
    padding-left: .75rem;
    padding-right: .75rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem
}

.py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.pb-2 {
    padding-bottom: .5rem
}

.pb-6 {
    padding-bottom: 1.5rem
}

.pl-10 {
    padding-left: 2.5rem
}

.pl-2 {
    padding-left: .5rem
}

.pl-3 {
    padding-left: .75rem
}

.pl-9 {
    padding-left: 2.25rem
}

.pr-3 {
    padding-right: .75rem
}

.pr-4 {
    padding-right: 1rem
}

.pt-0 {
    padding-top: 0
}

.pt-0\.5 {
    padding-top: .125rem
}

.pt-1 {
    padding-top: .25rem
}

.pt-1\.5 {
    padding-top: .375rem
}

.pt-2 {
    padding-top: .5rem
}

.pt-3 {
    padding-top: .75rem
}

.pt-5 {
    padding-top: 1.25rem
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.font-inter {
    font-family: Inter,sans-serif
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 1.33;
    letter-spacing: -.01em
}

.text-3xl {
    font-size: 1.88rem;
    line-height: 1.33;
    letter-spacing: -.01em
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: -.01em
}

.text-sm {
    font-size: .875rem;
    line-height: 1.5715
}

.text-xs {
    font-size: .75rem;
    line-height: 1.5
}

.font-bold {
    font-weight: 700
}

.font-medium {
    font-weight: 500
}

.font-semibold {
    font-weight: 600
}

.uppercase {
    text-transform: uppercase
}

.italic {
    font-style: italic
}

.tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.text-emerald-400 {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity))
}

.text-green-50 {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity))
}

.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity))
}

.text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgb(199 210 254 / var(--tw-text-opacity))
}

.text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity))
}

.text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity))
}

.text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity))
}

.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity))
}

.text-rose-50 {
    --tw-text-opacity: 1;
    color: rgb(255 241 242 / var(--tw-text-opacity))
}

.text-rose-500 {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity))
}

.text-sky-50 {
    --tw-text-opacity: 1;
    color: rgb(240 249 255 / var(--tw-text-opacity))
}

.text-sky-500 {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity))
}

.text-slate-200 {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))
}

.text-slate-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.text-slate-50 {
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity))
}

.text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))
}

.text-slate-600 {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))
}

.text-slate-700 {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity))
}

.text-slate-800 {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity))
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.underline {
    text-decoration-line: underline
}

.line-through {
    text-decoration-line: line-through
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.placeholder-slate-400::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(148 163 184 / var(--tw-placeholder-opacity))
}

.placeholder-slate-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(148 163 184 / var(--tw-placeholder-opacity))
}

.opacity-0 {
    opacity: 0
}

.opacity-100 {
    opacity: 1
}

.opacity-50 {
    opacity: .5
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .08), 0 4px 6px -2px rgba(0, 0, 0, .01);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.outline {
    outline-style: solid
}

.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
    transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
    transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
    transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s
}

.duration-100 {
    transition-duration: .1s
}

.duration-150 {
    transition-duration: .15s
}

.duration-200 {
    transition-duration: .2s
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4,0,.2,1)
}

.ease-out {
    transition-timing-function: cubic-bezier(0,0,.2,1)
}

.last\:mb-0:last-child {
    margin-bottom: 0
}

.last\:border-0:last-child {
    border-width: 0px
}

.hover\:border-slate-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity))
}

.hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity))
}

.hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity))
}

.hover\:bg-slate-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}

.hover\:bg-slate-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))
}

.hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity))
}

.hover\:text-rose-600:hover {
    --tw-text-opacity: 1;
    color: rgb(225 29 72 / var(--tw-text-opacity))
}

.hover\:text-slate-200:hover {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))
}

.hover\:text-slate-400:hover {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.hover\:text-slate-500:hover {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))
}

.hover\:text-slate-600:hover {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))
}

.hover\:text-slate-800:hover {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity))
}

.hover\:text-slate-900:hover {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity))
}

.hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.hover\:underline:hover {
    text-decoration-line: underline
}

.focus\:border-slate-300:focus {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity))
}

.focus\:ring-transparent:focus {
    --tw-ring-color: transparent
}

.group:hover .group-hover\:text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))
}

.group:hover .group-hover\:text-slate-800 {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity))
}

.group:hover .group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.group:hover .group-hover\:text-opacity-50 {
    --tw-text-opacity: .5
}

.sidebar-expanded .sidebar-expanded\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@media (min-width: 480px) {
    .xs\:block {
        display:block
    }
}

@media (min-width: 640px) {
    .sm\:col-span-6 {
        grid-column:span 6 / span 6
    }

    .sm\:mb-0 {
        margin-bottom: 0
    }

    .sm\:mr-0 {
        margin-right: 0
    }

    .sm\:mr-3 {
        margin-right: .75rem
    }

    .sm\:inline {
        display: inline
    }

    .sm\:flex {
        display: flex
    }

    .sm\:auto-cols-max {
        grid-auto-columns: max-content
    }

    .sm\:items-center {
        align-items: center
    }

    .sm\:justify-start {
        justify-content: flex-start
    }

    .sm\:justify-end {
        justify-content: flex-end
    }

    .sm\:justify-between {
        justify-content: space-between
    }

    .sm\:p-6 {
        padding: 1.5rem
    }

    .sm\:px-2 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
}

@media (min-width: 768px) {
    .md\:bottom-8 {
        bottom:2rem
    }

    .md\:left-auto {
        left: auto
    }

    .md\:right-0 {
        right: 0px
    }

    .md\:right-12 {
        right: 3rem
    }

    .md\:w-auto {
        width: auto
    }

    .md\:rounded {
        border-radius: .25rem
    }

    .md\:text-3xl {
        font-size: 1.88rem;
        line-height: 1.33;
        letter-spacing: -.01em
    }
}

@media (min-width: 1024px) {
    .lg\:static {
        position:static
    }

    .lg\:left-auto {
        left: auto
    }

    .lg\:top-auto {
        top: auto
    }

    .lg\:z-auto {
        z-index: auto
    }

    .lg\:block {
        display: block
    }

    .lg\:inline-flex {
        display: inline-flex
    }

    .lg\:hidden {
        display: none
    }

    .lg\:w-20 {
        width: 5rem
    }

    .lg\:translate-x-0 {
        --tw-translate-x: 0px;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:overflow-y-auto {
        overflow-y: auto
    }

    .lg\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .lg\:opacity-0 {
        opacity: 0
    }

    .sidebar-expanded .lg\:sidebar-expanded\:block {
        display: block
    }

    .sidebar-expanded .lg\:sidebar-expanded\:hidden {
        display: none
    }

    .sidebar-expanded .lg\:sidebar-expanded\:\!w-64 {
        width: 16rem!important;
    }

    .sidebar-expanded .lg\:sidebar-expanded\:opacity-100 {
        opacity: 1
    }
}

@media (min-width: 1280px) {
    .xl\:col-span-4 {
        grid-column:span 4 / span 4
    }

    .xl\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .xl\:col-span-8 {
        grid-column: span 8 / span 8
    }

    .xl\:block {
        display: block
    }
}

@media (min-width: 1536px) {
    .\32xl\:block {
        display:block
    }

    .\32xl\:hidden {
        display: none
    }

    .\32xl\:\!w-64 {
        width: 16rem!important
    }

    .\32xl\:opacity-100 {
        opacity: 1
    }
}
